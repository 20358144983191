import Openfort, {
  AuthPlayerResponse,
  AuthType,
  OAuthProvider,
  ShieldAuthentication,
  TokenType,
  TypedDataDomain,
  TypedDataField,
  EmbeddedState
} from '@openfort/openfort-js';

import { RampInstantSDK } from '@ramp-network/ramp-instant-sdk';
import { IHostConfig } from '@ramp-network/ramp-instant-sdk/dist/types/types';

export declare class AccountResponse {
    'id': string;
    // 'object': EntityTypeACCOUNT;
    'createdAt': number;
    'address': string;
    'ownerAddress': string;
    'deployed': boolean;
    'custodial': boolean;
    'embeddedSigner': boolean;
    /**
    * The chain ID.
    */
    'chainId': number;
    'accountType': string;
    'pendingOwnerAddress'?: string;
    // 'transactionIntents'?: Array<PlayerResponseTransactionIntentsInner>;
    // 'player': EntityIdResponse;
    constructor();
}

// const openfortConfig = {
//     publicKey: "pk_test_3865255c-910a-5ce3-8ce2-738c38e80bc2",
//     shieldApiKey: "8715a505-9a6b-40e7-877a-8f2f31f7a0e4", //"e36cc5aa-cf98-4138-bd39-6142e1125b93",
//     shieldEncryptionShare: "rWUfTiTgcoQvM6trjUNL6btJPTI9fNsQaMgDy1MkINY=",
// };

// const openfort = new Openfort(openfortConfig.publicKey, openfortConfig.shieldApiKey, openfortConfig.shieldEncryptionShare);
// const chainId = 97;

var openfort : any;
var chainId : number;

export const OFGetChainId = () => {
    return chainId;
}
export const OFSetChainId = (_chainId: number) => {
    chainId = _chainId;
}

export const OFGetOpenfort = () => {
    return openfort;
}

export const OFInitialize = (publicKey: string, shieldApiKey: string, shieldEncryptionShare: string) => {
    console.log({publicKey});
    console.log({shieldApiKey});
    console.log({shieldEncryptionShare});

    openfort = new Openfort(publicKey, shieldApiKey, shieldEncryptionShare);
    return openfort;
}

export const OFauthenticateWithThirdPartyProvider = async(identityToken: string): Promise<AuthPlayerResponse> => {
    try {
        console.log({identityToken});
        console.log({openfort});
        return await openfort.authenticateWithThirdPartyProvider(OAuthProvider.Firebase, identityToken, TokenType.IdToken);
    } catch (error) {
        console.error('Error authenticating with Openfort:', error);
        throw error;
    }
}

export const OFgetEmbeddedState = () => {
    try {
        const embeddedState = openfort.getEmbeddedState();
        console.log(embeddedState === EmbeddedState.EMBEDDED_SIGNER_NOT_CONFIGURED ? ("EMBEDDED_SIGNER_NOT_CONFIGURED") : (
            embeddedState === EmbeddedState.CREATING_ACCOUNT ? ("CREATING_ACCOUNT") : (
                embeddedState === EmbeddedState.READY ? ("READY") : (
                    "ELSE"
                )
            )
        ));
        return embeddedState;
    } catch (error) {
        console.error('Error retrieving embedded state from Openfort:', error);
        throw error;
    }
}

export const OFsignMessage = async (message: string): Promise<string | null> => {
    try {
        return await openfort.signMessage(message);
    } catch (error) {
        console.error("Error:", error);
        return null
    }
}

export const OFsignTypedData = async (domain: TypedDataDomain, types: Record<string, Array<TypedDataField>>, value: Record<string, any>): Promise<string | null> => {
    try {
        return await openfort.signTypedData(domain, types, value);
    } catch (error) {
        console.error("Error:", error);
        return null
    }
}

export const OFsetAutomaticRecoveryMethod = async (identityToken: string) => {
    try {
        console.log("=====----->>>>>", "setAutomaticRecoveryMethod", 
            identityToken.slice(0, 5) + '...' + identityToken.slice(-5));
        const shieldAuth: ShieldAuthentication = {
            auth: AuthType.OPENFORT,
            token: identityToken,
            authProvider: "firebase",
            tokenType: "idToken",
        };
        await openfort.configureEmbeddedSigner(chainId, shieldAuth);
    } catch (error) {
        console.error('Error authenticating with Openfort:', error);
        throw error;
    }
}

export const OFlogout = async () => {
    try {
        await openfort.logout();
    } catch (error) {
        console.error('Error logging out with Openfort:', error);
        throw error;
    }
}

export const OFsendTransaction = async (transactionIntent: any): Promise<any | null> => {
    try {
        console.log("----->>>>>", "sendTransaction()", transactionIntent.nextAction);
        if (transactionIntent && transactionIntent.nextAction) {
            const response = await openfort.sendSignatureTransactionIntentRequest(
                transactionIntent.id,
                transactionIntent.nextAction.payload.userOperationHash
            );
            console.log({response});

            return response;
        } else {
            // return null;
            // 이 단계까지 올 수 없어야 한다.
            throw new Error("Transaction Intent Invalid");
        }
    } catch (error: any) {
        // return null
        console.error('Error call Send Transaction API:', error);
        console.error({error});
        if (error.message) {            
            throw error.message;
        }
        throw error;
    }
}


var RampIns: any;

export const OpenRamp = async (
    mode: string,
    hostAppName: string,
    hostLogoUrl: string,
    hostApiKey: string,
    userAddress: string
): Promise<any | null> => {
    try {
        let options: IHostConfig | undefined = undefined;
        if (mode === 'live') {
            options = {
                hostAppName,
                hostLogoUrl,
                hostApiKey,
                userAddress,
                defaultAsset: 'BSC_BNB',
                enabledFlows: ['ONRAMP'],
            }
        } else {
            options = {
                hostAppName,
                hostLogoUrl,
                hostApiKey,
                url: 'https://app.demo.ramp.network',
                userAddress,
                defaultAsset: 'BSC_BNB',
                enabledFlows: ['ONRAMP'],
            };
        }

        RampIns = new RampInstantSDK(options);

        console.error('Open Ramp show:');

        return RampIns.show();

    } catch (error: any) {
        // return null
        console.error('Error Open Ramp SDK:', error);
        console.error({error});
        if (error.message) {            
            throw error.message;
        }
        throw error;
    }
}