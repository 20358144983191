declare global {
    interface Window {
        web3Plugin: any;
        web3ChainId: any;
        ethereum: any;
        auth: any;
        googleProvider: any;
        facebookProvider: any;
        firebase: any;
    }
}

import Web3 from "web3";
import {
    OFSetChainId,
    OFGetChainId,
    OFGetOpenfort,
    OFsendTransaction,
    OFauthenticateWithThirdPartyProvider,
    OFgetEmbeddedState,
    OFsetAutomaticRecoveryMethod,
    OFlogout,
    OFsignMessage,
    OFInitialize,
    OpenRamp,
} from "./openfort";

import {
    EthereumClient,
    w3mConnectors,
    w3mProvider,
} from "@web3modal/ethereum";

import { Web3Modal } from "@web3modal/html";

import { Modal, ModalOptions } from "flowbite";

import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/messaging";
import "firebase/compat/analytics";

window.firebase = firebase;


// load web3gl to connect to unity
window.web3Plugin = {
    networkId: 0,
    _connectWallet,
    _connectAccount: "",
    _disconnectAccount,
    _disconnectAccountResponse: "",
    _signMessage,
    _signMessageResponse: "",
    _callContract,
    _callContractResponse: "",
    _sendContract,
    _sendContractResponse: "",
    _openInputModal,
    _openInputModalResponse: "",
    _fcmToken: "",
    _openfortProc,
    _walletProvider: "",
    _linkedAddress: "",
};

console.log("web3gl", window.web3Plugin)

let web3 = new Web3(Web3.givenProvider);

// 0. Import wagmi dependencies
import { bsc, bscTestnet } from "@wagmi/core/chains";
import {
configureChains,
createConfig,
// fetchFeeData,
fetchBalance,
connect,
disconnect,
signMessage,
getNetwork,
switchNetwork,
readContract,
prepareWriteContract,
writeContract,
waitForTransaction,
watchAccount,
watchNetwork,
watchPublicClient,
watchWalletClient,
//watchPendingTransactions 
} from "@wagmi/core";
import { getAccount as wagmiGetAccount } from "@wagmi/core"

import { publicProvider } from "@wagmi/core/providers/public";
import { infuraProvider } from "@wagmi/core/providers/infura";
import { InjectedConnector } from "@wagmi/core/connectors";
import { MetaMaskConnector } from "@wagmi/core/connectors/metaMask";

// 1. Define chains
const chains = [bsc, bscTestnet];
const projectId = "d364ce84579757b8f0ac19772c349464";

// 2. Configure wagmi client
const _MetaMaskConnector = new MetaMaskConnector({
    options: {
        shimDisconnect: false,
        UNSTABLE_shimOnConnectSelectAccount: true,
    },
})

const { publicClient } = configureChains(chains,
    [
        // publicProvider({ stallTimeout: 1_000 }),
        publicProvider(),
        w3mProvider({ projectId }),
        infuraProvider({
            apiKey: "7f0b11daf6ab469d86444efca095d4c3"
        }),
    ]);
const wagmiConfig = createConfig({
    autoConnect: true,
    connectors: [
        // ...w3mConnectors({ chains, version: 2, projectId }),
        ...w3mConnectors({ chains, projectId }),
        //new MetaMaskConnector({ chains }),
        new InjectedConnector({
            options: {
                name: 'Injected',
                shimDisconnect: false,
            },
        }),
    ],
    publicClient,
});

// 3. Create ethereum and modal clients
const ethereumClient = new EthereumClient(wagmiConfig, chains);
const web3modal = new Web3Modal({ projectId }, ethereumClient)

// ================================================================
// >> Connect Wallet Modal
const walletModal = document.querySelector("#crypto-modal");
// options with default values
const options: ModalOptions = {
    placement: 'center',
    backdropClasses: 'bg-gray-900 bg-opacity-50 dark:bg-opacity-80 fixed inset-0 z-40',
    onHide: () => {
        console.log('modal is hidden');
        if (window.web3Plugin._walletProvider == "openfort") {
            console.log("===== onHide() OPENFORT PASS")
            return;
        }
        const account = wagmiGetAccount();
        if (isClickModalButton == false && (account == null || account === undefined || account?.isConnected == false))
            window.web3Plugin._connectAccount = "Error1";
        /*
        if(typeof web3 === 'undefined' )
        {
          window.web3Plugin._connectAccount = "Error";
        }
        else
        {
          let result = web3.utils.isAddress(window.web3Plugin._connectAccount)
          console.log("web3.utils.isAddress : " + result);
          if(result == "false")
            window.web3Plugin._connectAccount = "Error";
        }    
        */
    },
    onShow: () => {
        console.log('modal is shown');
    },
    onToggle: () => {
        console.log('modal has been toggled');
    }
};

const WalletSelectModal = new Modal(walletModal as HTMLElement, options);

const ModalCloseButton = document.querySelector("#ModalCloseButton")!;
ModalCloseButton.addEventListener('click', () => {
    console.log("Click1");
    window.web3Plugin._connectAccount = "Error2";
    WalletSelectModal.toggle();
})

// >> Modal Buttons
var isClickModalButton = false;
const connectButton_MetaMask = document.querySelector("#ConnectWallet_MetaMask")!;
connectButton_MetaMask.addEventListener('click', () => {
    console.log("Click2");
    isClickModalButton = true;
    connect_MetaMask();
})
const connectButton_WalletConnect = document.querySelector("#ConnectWallet_WalletConnect")!;
connectButton_WalletConnect.addEventListener('click', () => {
    console.log("Click3");
    isClickModalButton = true;
    connect_WalletConnect();
})
const connectButton_Openfort = document.querySelector("#ConnectWallet_Openfort")!;
connectButton_Openfort.addEventListener('click', async () => {
    console.log("Click4");
    isClickModalButton = true;
    await connect_Openfort();
})
// << Modal Buttons
// << Connect Wallet Modal
// ================================================================

async function _connectWallet(chainId: any) {

    // TODO : 분리요망, 지갑 연결 과 지갑 선택 화면에 중복 사용으로 혼란을 가져옴
    // 더구나 어떤 목적으로 불리는지 변수가 없어서 알 수가 없음
    if (window.web3Plugin._linkedAddress == "" || window.web3Plugin._walletProvider == "openfort") {
        await _resetWeb3("", "");
    }

    isClickModalButton = false;
    const account = wagmiGetAccount();
    window.web3ChainId = chainId;

    if (account == null || account === undefined || account?.isConnected == false) {
        console.log("connect() >> window.web3ChainId : " + window.web3ChainId);
        WalletSelectModal.toggle();
    }
    else {
        if (account != null && account?.isConnected) {
            window.web3Plugin._connectAccount = account.address;
        }
        else {
            window.web3Plugin._connectAccount = "Error3";
        }
    }
}

async function connect_MetaMask() {
    try {
        const result = await connect({
            connector: _MetaMaskConnector
        })

        window.web3Plugin.networkId = result.chain.id;
        console.log("connect_MetaMask() >> web3Plugin.networkId : " + window.web3Plugin.networkId);
        await checkNetwork();

        const account = wagmiGetAccount();
        window.web3Plugin._connectAccount = account.address;
        console.log("connect_MetaMask() >> window.web3Plugin._connectAccount : " + window.web3Plugin._connectAccount);
    }
    catch (error: any) {
        console.log("connect_MetaMask() >> error : " + error.message);
        window.web3Plugin._connectAccount = "Error : " + error.message;
        if (error.message == "Connector not found") {
            window.open('https://metamask.io')
        }
    }

    WalletSelectModal.hide();
}

function connect_WalletConnect() {
    web3modal.openModal()
}

async function connect_Openfort() {
    WalletSelectModal.hide();

    try {
        await _authOpenfort();
        window.web3Plugin._connectAccount = window.web3Plugin._linkedAddress || "OPENFORT" + "0xopenfortfakeaddress";
        console.log("connect_Openfort() >> window.web3Plugin._connectAccount : " + window.web3Plugin._connectAccount);
        window.web3Plugin._walletProvider = "openfort";
    }
    catch (error: any) {
        console.log("connect_Openfort() >> error : " + error.message);
        window.web3Plugin._connectAccount = "Error : " + error.message;
    }
}

async function _disconnectAccount() {
    try {

        _MetaMaskConnector.options.shimDisconnect = true;

        await disconnect();

        window.localStorage.clear();

        window.web3Plugin._disconnectAccountResponse = "disconnect";
        window.web3Plugin._connectAccount = "";

    } catch (error: any) {
        window.web3Plugin._disconnectAccountResponse = error.message;
    }
}

async function _signMessage(message: string) {

    try {
        const signature = await signMessage({
            message: message,
        })

        window.web3Plugin._signMessageResponse = signature;
    } catch (error: any) {
        window.web3Plugin._signMessageResponse = "Error : " + error.message;
    }

    WalletSelectModal.hide();
}

async function _callContract(method: any, _abi: any, contract: any, _args: any) {
    console.log("method : " + method);
    console.log("abi : " + _abi);
    console.log("contract : " + contract);
    console.log(JSON.parse(_abi));
    console.log("args : " + _args);
    console.log(...JSON.parse(_args));

    try {
        await checkNetwork();

        var _abiJson = JSON.parse(_abi);
        var _argsJson = JSON.parse(_args);

        const data = await readContract({
            address: contract,
            abi: _abiJson,
            functionName: method,
            args: _argsJson,
            chainId: window.web3ChainId,
        })

        window.web3Plugin._callContractResponse = data.toString();
        console.log("_callContract >> data : " + data);
    } catch (error: any) {
        console.log("_callContract >> Error >> " + error);
        console.log("_callContract >> Error >> " + error.name);
        console.log("_callContract >> Error >> " + error.message);
        window.web3Plugin._callContractResponse = "Error : " + error.message.toString();
    }



    // .then((result) => window.web3Plugin._callContractResponse = result)
    // .catch((error) => window.web3Plugin._callContractResponse = error.message);


}

async function _sendContract(method: any, _abi: any, contract: any, _args: any, _value: any, _gasLimit: any, _gasPrice: any) {
    console.log("method : " + method);
    console.log("abi : " + _abi);
    console.log("contract : " + contract);
    console.log("_value : " + _value);
    console.log(JSON.parse(_abi));
    console.log("args : " + _args);
    console.log("args : " + JSON.parse(_args));

    try {
        await checkNetwork();

        if (method == "transfer") {
            const argsarr = JSON.parse(_args);
            const input = argsarr[1]; // Note: this is a string, e.g. user input
            console.log("input : " + input);
            const amount = web3.utils.toWei(input, 'ether');
            //const amount = parseEther(input);    
            argsarr[1] = amount;
            _args = JSON.stringify(argsarr);
            console.log("_args : " + _args);
        }

        var _abiJson = JSON.parse(_abi);
        var _argsJson = JSON.parse(_args);

        /*
            const feeData = await fetchFeeData({
              chainId: window.web3ChainId,
              formatUnits: 'gwei',
            })
            
            const gas = await publicClient.estimateContractGas({
              address: contract,
              abi: _abiJson,
              functionName: method,
              account: window.web3Plugin._connectAccount
            })
        
            console.log("feeData : " + feeData.gasPrice + " , " + feeData.maxPriorityFeePerGas);
          
            gasLimit = feeData.gasPrice;
            gasPrice = feeData.gasPrice;
            */
        const { request } = await prepareWriteContract({
            address: contract,
            abi: _abiJson,
            functionName: method,
            args: _argsJson,
            chainId: window.web3ChainId,
            value: _value,
        })
        //console.log("gasLimit : " + gasLimit + " , gasPrice : " + web3.utils.toHex(gasPrice));
        const { hash } = await writeContract(request)
        console.log("writeContract Complete >> hash : " + hash + " , waitForTransaction Start");
        const data = await waitForTransaction({
            confirmations: 3,
            chainId: window.web3ChainId,
            hash: hash,
        })
        const stringified = JSON.stringify(data, replacer);
        console.log("waitForTransaction Complete >> data : " + stringified);
        console.log("data.status : " + data.status + " , data.transactionHash : " + data.transactionHash);

        if (data.status == 'success') {
            window.web3Plugin._sendContractResponse = data.transactionHash;
            console.log("_sendContract >> data.transactionHash : " + data.transactionHash);
        }
        else {
            window.web3Plugin._sendContractResponse = "ERR_waitForTransaction";
        }

    } catch (error: any) {
        console.log("_sendContract >> Error >> " + error);
        console.log("_sendContract >> Error name >> " + error.name);
        console.log("_sendContract >> Error message >> " + error.message);
        window.web3Plugin._sendContractResponse = error.message.toString();
    }

}

async function checkNetwork() {
    console.log(window.web3ChainId, window.web3Plugin._walletProvider);
    if (window.web3Plugin._walletProvider == "openfort") {
        console.log("===== checkNetwork() OPENFORT PASS")
        return;
    }

    const { chain, chains } = getNetwork();
    console.log("checkNetwork 0 : " + JSON.stringify(chain) + ", " + JSON.stringify(chains));
    console.log("checkNetwork 1 : " + chain?.id + ", " + window.web3ChainId);
    if (chain?.id != window.web3ChainId) {
        try {
            console.log("checkNetwork >> switchNetwork ");
            // const network = await switchNetwork({
            //   chainId: window.web3ChainId,
            // })
            await switchNetwork({
                chainId: window.web3ChainId,
            })
        } catch (ex: any) {
            if (ex.code != 4001) { // 4001 user reject
                await addEthereumChain();
            }
        }
    }
}

// add new wallet to in metamask
async function addEthereumChain() {
    console.log("checkNetwork >> addEthereumChain ");
    //const account = (await web3.eth.getAccounts())[0];
    // const account = getAccount();
    // console.log("account : " + JSON.stringify(account));
    // fetch https://chainid.network/chains.json
    const response = await fetch("https://chainid.network/chains.json");
    const chains = await response.json();

    // find chain with network id
    const chain = chains.find((chain: any) => chain.chainId == window.web3ChainId);

    const params = {
        chainId: "0x" + chain.chainId.toString(16), // A 0x-prefixed hexadecimal string
        chainName: chain.name,
        nativeCurrency: {
            name: chain.nativeCurrency.name,
            symbol: chain.nativeCurrency.symbol, // 2-6 characters long
            decimals: chain.nativeCurrency.decimals,
        },
        rpcUrls: chain.rpc,
        blockExplorerUrls: [chain.explorers && chain.explorers.length > 0 && chain.explorers[0].url ? chain.explorers[0].url : chain.infoURL],
    };

    await window.ethereum
        .request({
            method: 'wallet_addEthereumChain',
            //params: [params, account],
            params: [params],
        })
        .catch((error: any) => {
            // I give up
            console.log("addEthereumChain >> Error : " + error);
            //window.location.reload();
        });
}

const replacer = (_key: any, value: any) =>
    typeof value === "bigint" ? value.toString() : value;

const reviver = (key: any, value: any) => (key === "big" ? BigInt(value) : value);

// << Function
// ================================================================
// >> Watch
const _watchAccount = watchAccount((account) => {
    console.log("==========----------==========");
    console.log("watchAccount > " + account);
    console.log({ account })

    if (window.web3Plugin._walletProvider == "openfort") {
        console.log("===== watchAccount() OPENFORT PASS")
        return;
    }

    if (account.isConnected) {
        console.log(account);
        console.log("account.address : " + account.address);
        if (window.web3Plugin._connectAccount != "" && account.address != window.web3Plugin._connectAccount) {
            window.web3Plugin._connectAccount = account.address;
            window.location.reload();
        }
        else
            window.web3Plugin._connectAccount = account.address;

        _MetaMaskConnector.options.shimDisconnect = false;
    }
    else {
        window.web3Plugin._connectAccount = "";
    }
})

const _watchNetwork = watchNetwork((network) => {
    console.log("watchNetwork > " + JSON.stringify(network));
    //const obj = JSON.parse(JSON.stringify(network));
    //console.log(obj.chain.id);
    window.web3Plugin.networkId = network.chain?.id;
})

const _watchPublicClient = watchPublicClient(
    {
        chainId: window.web3ChainId,
    },
    (publicClient) => {
        console.log("watchPublicClient > " + JSON.stringify(publicClient));
    })

const _watchWalletClient = watchWalletClient(
    {
        chainId: window.web3ChainId,
    },
    (walletClient) => {
        console.log("_watchWalletClient > " + JSON.stringify(walletClient));
        // web3 = new Web3(walletClient.);
    })
// const _watchPendingTransactions = watchPendingTransactions(
//   {
//     chainId: window.web3ChainId,
//   },
//   (transaction) => console.log("_watchPendingTransactions >> " + JSON.stringify(transaction)),
// )
// ================================================================

// ================================================================
// >> InputField Modal
const inputModal: HTMLElement = document.querySelector("#input-modal")!;
let ConfirmInput = 0;

const InputModal_InputField: any = document.querySelector("#InputModal_InputField")!;
// options with default values
const inputModal_options: ModalOptions = {
    placement: 'center',
    backdropClasses: 'bg-gray-900 bg-opacity-50 dark:bg-opacity-80 fixed inset-0 z-40',
    onHide: () => {
        console.log('inputModal is hidden');
        if (ConfirmInput != 1) {
            window.web3Plugin._openInputModalResponse = "$_BLANK_$";
            ConfirmInput = 0;
        }
    },
    onShow: () => {
        console.log('inputModal is shown');
        ConfirmInput = 0;
        InputModal_InputField.focus();
    },
    onToggle: () => {
        console.log('inputModal has been toggled');
    }
};

const InputModal = new Modal(inputModal, inputModal_options);

const InputModalCloseButton = document.querySelector("#InputModalCloseButton")!;
InputModalCloseButton.addEventListener('click', () => {
    console.log("Click");
    window.web3Plugin._openInputModalResponse = "$_BLANK_$";
    InputModal.toggle();
})

async function _openInputModal() {
    InputModal_InputField.value = "";
    InputModal.toggle();
}

const InputModal_SubmitButton: any = document.querySelector("#InputModal_SubmitButton")!;
InputModal_SubmitButton.addEventListener('click', () => {
    console.log("InputModal_InputField.value : " + InputModal_InputField.value);

    ConfirmInput = 1;
    if (InputModal_InputField.value != "")
        window.web3Plugin._openInputModalResponse = InputModal_InputField.value;
    else
        window.web3Plugin._openInputModalResponse = "$_BLANK_$";

    console.log("window.web3Plugin._openInputModalResponse : " + window.web3Plugin._openInputModalResponse);
    InputModal.toggle();
})

InputModal_InputField.addEventListener("keyup", function (event: any) {
    if (event.keyCode === 13) {
        event.preventDefault();
        InputModal_SubmitButton.click();
    }
});
// ================================================================

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseApp = firebase.initializeApp({
    // apiKey: "AIzaSyCEt19mYwJQPhT7-86klXPs6il_1e3VFd4",
    // authDomain: "kingdom-nft-dev.firebaseapp.com",
    // projectId: "kingdom-nft-dev",
    // storageBucket: "kingdom-nft-dev.appspot.com",
    // messagingSenderId: "382291140510",
    // appId: "1:382291140510:web:c237e68c961047b124008f",
    // measurementId: "G-9GK7MHT5TJ"
    apiKey: "{{{ FIREBASE_API_KEY }}}",
    authDomain: "{{{ FIREBASE_AUTH_DOMAIN }}}",
    projectId: "{{{ FIREBASE_PROJECT_ID }}}",
    storageBucket: "{{{ FIREBASE_STORAGE_BUCKET }}}",
    messagingSenderId: "{{{ FIREBASE_MASSAGING_SENDER_ID }}}",
    appId: "{{{ FIREBASE_APP_ID }}}",
    measurementId: "{{{ FIREBASE_MEASUREMENT_ID }}}",
});

// Initialize Firebase
window.auth = firebaseApp.auth!();
// Initialize Analytics and get a reference to the service
const analytics = firebaseApp.analytics();

window.googleProvider = new firebase.auth.GoogleAuthProvider();
window.facebookProvider = new firebase.auth.FacebookAuthProvider();

// Initialize Firebase Cloud Messaging and get a reference to the service
const messaging = firebase.messaging();
// Add the public key generated from the console here.
//messaging.getToken(messaging, {vapidKey: "BLWnD7vSyzPlJh8EDjh_p0Jt-H1ALhDtmXNu8R25gTLMiEZ288rG7DD-MSeBcCfAffT7cqJWFCha2DzEF28b-Do"}).then((currentToken) => {
messaging.getToken({ vapidKey: "{{{ FIREBASE_WEB_PUSH_ID }}}" }).then((currentToken) => {

    if (currentToken) {
        // Send the token to your server and update the UI if necessary
        // ...
        console.log('messaging >> Get FCM Token Sucess : ' + currentToken);
        window.web3Plugin._fcmToken = currentToken;
        localStorage.setItem('fcm', currentToken);
    } else {
        // Show permission request UI
        console.log('messaging >> No registration token available. Request permission to generate one.');
        window.web3Plugin._fcmToken = "";
        // ...
    }
}).catch((err) => {
    console.log('messaging >> An error occurred while retrieving token. ', err);
    window.web3Plugin._fcmToken = "";
    // ...
});

messaging.onMessage((payload: any) => {
    console.log('Message received. ', payload);
    var title = payload.notification.title;
    var options = {
        body: payload.notification.body,
        icon: '/img/Win_96x96.png'
    };
    //alert("알람발생");
    var notification = new Notification(title, options);
});
/*
export const web3Modal = new Web3Modal(
  {
    projectId,
    walletImages: {
      safe: "https://pbs.twimg.com/profile_images/1566773491764023297/IvmCdGnM_400x400.jpg",
    },
  },
  ethereumClient
);

import 
{ 
  WagmiConfig, createConfig, configureChains, mainnet, useAccount, useConnect, useDisconnect 
} 
from 'https://cdn.jsdelivr.net/npm/wagmi@1.4.1/+esm'

import { publicProvider } from '@wagmi/core/providers/public'

//import { MetaMaskConnector,  WalletConnectConnector} from 'https://cdn.jsdelivr.net/npm/wagmi@1.4.1/dist/index.min.js'

// 1. Define chains
const chains = [bsc, bscTestnet];
const w3mProjectId = "d364ce84579757b8f0ac19772c349464";

// 2. Configure wagmi client
const { publicClient } = configureChains(chains, [publicProvider({ w3mProjectId })]);
const config = createConfig({
  autoConnect: true,
  connectors: [
    ...w3mConnectors({ chains, version: 2, w3mProjectId }),
    new MetaMaskConnector({ chains }),
    new  WalletConnectConnector({
      chains,
      options: {
        projectId: w3mProjectId,
      },
    }),
  ],
  publicClient,
});

// 3. Create ethereum and modal clients
const ethereumClient = new EthereumClient(config, chains);
export const web3Modal = new Web3Modal(
  {
    w3mProjectId,
    walletImages: {
      safe: "https://pbs.twimg.com/profile_images/1566773491764023297/IvmCdGnM_400x400.jpg",
    },
  },
  ethereumClient
);
*/
/*
import { WagmiConfig, createConfig, configureChains, mainnet, useAccount, useConnect, useDisconnect } from 'wagmi'

import { publicProvider } from 'wagmi/providers'

import { MetaMaskConnector } from 'wagmi/connectors'
import { WalletConnectConnector } from 'wagmi/connectors'

import { Web3Modal } from "https://unpkg.com/@web3modal/html@2.7.1";

// 1. Define chains
const chains = [bsc, bscTestnet];
const w3mProjectId = "d364ce84579757b8f0ac19772c349464";

// 2. Configure wagmi client
const { publicClient } = configureChains(chains, [publicProvider({ w3mProjectId })]);
const config = createConfig({
  autoConnect: true,
  connectors: [
    ...w3mConnectors({ chains, version: 2, w3mProjectId }),
    new MetaMaskConnector({ chains }),
    new  WalletConnectConnector({
      chains,
      options: {
        projectId: w3mProjectId,
      },
    }),
  ],
  publicClient,
});

// 3. Create ethereum and modal clients
const ethereumClient = new EthereumClient(config, chains);
export const web3Modal = new Web3Modal(
  {
    w3mProjectId,
    walletImages: {
      safe: "https://pbs.twimg.com/profile_images/1566773491764023297/IvmCdGnM_400x400.jpg",
    },
  },
  ethereumClient
);

*/
/*
import {
  EthereumClient,
  w3mConnectors,
  w3mProvider,
  WagmiCore,
  WagmiCoreChains,
  WagmiCoreConnectors,
} from "https://unpkg.com/@web3modal/ethereum@2.7.1";

import { Web3Modal } from "https://unpkg.com/@web3modal/html@2.7.1";

// 0. Import wagmi dependencies
const { bsc, bscTestnet } = WagmiCoreChains;
const { configureChains, createConfig } = WagmiCore;

// 1. Define chains
const chains = [bsc, bscTestnet];
const w3mProjectId = "d364ce84579757b8f0ac19772c349464";

// 2. Configure wagmi client
const { publicClient } = configureChains(chains, [w3mProvider({ w3mProjectId })]);
const wagmiConfig = createConfig({
  autoConnect: true,
  connectors: [
    ...w3mConnectors({ chains, version: 2, w3mProjectId }),
    new WagmiCoreConnectors.MetaMaskConnector({ chains }),
    new  WagmiCoreConnectors.WalletConnectConnector({
      chains,
      options: {
        projectId: w3mProjectId,
      },
    }),
  ],
  publicClient,
});

// 3. Create ethereum and modal clients
const ethereumClient = new EthereumClient(wagmiConfig, chains);
export const web3Modal = new Web3Modal(
  {
    w3mProjectId,
    walletImages: {
      safe: "https://pbs.twimg.com/profile_images/1566773491764023297/IvmCdGnM_400x400.jpg",
    },
  },
  ethereumClient
);

*/


async function _openfortProc(functionName: any, jsonData: any) {
    console.log("==========----------==========");
    console.log({ functionName });
    console.log({ jsonData });

    if (functionName == "SetLinkedAddress") {
        await _resetWeb3(jsonData.linkedAddress, jsonData.walletProvider);

        window.web3Plugin._walletProvider = jsonData.walletProvider;
        window.web3Plugin._linkedAddress = jsonData.linkedAddress;
        if (jsonData.walletProvider == "openfort") {
            window.web3Plugin._connectAccount = jsonData.linkedAddress;
            try {
                await _authOpenfort();
            } catch (ex) {
                console.error(ex);
            }
        }

        return {
            walletProvider: window.web3Plugin._walletProvider,
            linkedAddress: window.web3Plugin._linkedAddress,
        }
    } else if (functionName == "SetChainID") {
        window.web3ChainId = jsonData.chainId;
        OFSetChainId(jsonData.chainId);
        const openfortChainId = OFGetChainId();
        console.log({ openfortChainId });
        return {
            web3ChainId: window.web3ChainId
        }
    } else if (functionName == "SendTransaction") {
        const openfort = OFGetOpenfort();
        console.log({ openfort });

        if (!openfort) {
            throw "openfort not initialized";
        }

        let transactionIntent = JSON.parse(jsonData.transactionIntent);
        console.log({ transactionIntent });
        console.log("----->>>>>", "OFsendTransaction()", transactionIntent.nextAction);

        const sender = transactionIntent.userOperation.sender;
        await _verifyBalance(sender);

        const response = await OFsendTransaction(transactionIntent);
        console.log({ response });
        const error = response.response.error;
        if (error) {
            throw error.reason;
        }
        if (response.response?.transactionHash == null) {
            throw "Error : Null Transaction";
        }

        const txHash = response.response?.transactionHash;

        console.log("=====-----===== OFsendTransaction Complete >> hash : " + txHash + " , waitForTransaction Start");
        const data = await waitForTransaction({
            confirmations: 3,
            chainId: window.web3ChainId,
            hash: txHash,
        })
        const stringified = JSON.stringify(data, replacer);
        console.log("=====-----===== waitForTransaction Complete >> data : " + stringified);
        console.log("=====-----===== data.status : " + data.status + " , data.transactionHash : " + data.transactionHash);

        if (data.status == 'success') {
            window.web3Plugin._sendContractResponse = data.transactionHash;
            console.log("=====-----===== OFsendTransaction >> data.transactionHash : " + data.transactionHash);
        }
        else {
            window.web3Plugin._sendContractResponse = "ERR_waitForTransaction";
        }

        return {
            txHash: window.web3Plugin._sendContractResponse
        }
    } else if (functionName == "GetIdentityToken") {
        const user = window.auth.currentUser;
        let identityToken = "";
        if (user) {
            identityToken = await user.getIdToken();
        }
        return {
            idToken: identityToken
        }
    } else if (functionName == "SetOpenfortConfig") {
        const publicKey = jsonData.publicKey;
        const shieldApiKey = jsonData.shieldApiKey;
        const shieldEncryptionShare = jsonData.shieldEncryptionShare;
        OFInitialize(
            publicKey,
            shieldApiKey,
            shieldEncryptionShare
        )

        if (window.web3Plugin._walletProvider == "openfort") {
            try {
                const signTest = await _authOpenfort();
                return {
                    signTest: signTest
                }
            } catch (ex) {
                throw ex;
            }
        } else {
            return {
                message: "provider is not openfort"
            }
        }
    } else if (functionName == "OpenRamp") {
        console.log("OpenRamp");
        try {
            var mode = jsonData.mode;
            var hostAppName = jsonData.hostAppName;
            var hostLogoUrl = jsonData.hostLogoUrl;
            var hostApiKey = jsonData.hostApiKey;
            var userAddress = jsonData.userAddress;
            console.log("mode " + mode);
            console.log("hostAppName " + hostAppName);
            console.log("hostLogoUrl " + hostLogoUrl);
            console.log("hostApiKey " + hostApiKey);
            console.log("userAddress " + userAddress);

            const result = await OpenRamp(mode, hostAppName, hostLogoUrl, hostApiKey, userAddress);

            const error = result.response.error;

            return {
                message: result
            }
        }
        catch (error: any)
        {
            return {
                message: "OpenRamp fail"
            }
        }
    } else if (functionName == "GetBalance") {
        console.log("GetBalance");
        try {
            var userAddress = jsonData.userAddress;
            console.log("userAddress " + userAddress);

            const result = await _verifyBalance(userAddress);

            return {
                message: result
            }
        }
        catch (error: any) {
            return {
                message: "GetBalance fail"
            }
        }
    }

    console.log("==========----------==========");
}

async function _authOpenfort() {
    const openfort = OFGetOpenfort();
    console.log({ openfort });

    if (!openfort) {
        throw "openfort not initialized";
    }

    try {
        const user = window.auth.currentUser;
        const identityToken = await user.getIdToken();
        console.log(1, { identityToken });
        const result = await OFauthenticateWithThirdPartyProvider(identityToken);
        console.log(2, { result });

        const status1 = OFgetEmbeddedState();
        console.log(3, { status1 });

        if (status1 != 4) {
            const result2 = await OFsetAutomaticRecoveryMethod(identityToken);
            console.log(4, { result2 });
            const status2 = OFgetEmbeddedState();
            console.log(5, { status2 });
        }
    } catch (ex) {
        console.error(ex);
        await OFlogout();
    }

    const signTest = await OFsignMessage("kingdom");
    console.log({ signTest });

    return signTest;
}

async function _disconnectOpenfort() {
    const openfort = OFGetOpenfort();
    console.log({ openfort });

    if (openfort) {
        await OFlogout();
    } else {
        console.log("openfort not initialized");
    }
}

async function _disconnectWeb3() {
    const wagmiAccount = await wagmiGetAccount();
    console.log({ wagmiAccount });
    if (wagmiAccount?.isConnected) {
        _MetaMaskConnector.options.shimDisconnect = true;
        await disconnect();
    }
}

async function _resetWeb3(address: any, provider: any) {
    console.log("_resetWeb3", address, provider);
    address = "" + address;
    if (address == "") {
        await _disconnectOpenfort();
        await _disconnectWeb3();
    } else {
        if (provider == "openfort") {
            await _disconnectWeb3();
        } else {
            await _disconnectOpenfort();
        }
    }

    const wagmiAccount = await wagmiGetAccount();
    console.log('after', { wagmiAccount });
}

async function _verifyBalance(address: any) {
    const chainId = window.web3ChainId;
    console.log({ address, chainId })
    const balance = await fetchBalance({ address, chainId });
    console.log({ balance });
    if (balance.value < 1000000000000000n) {
        throw new Error("Insufficient BNB Balance!");
    }

    return balance.formatted;
}